import GORoundLogo from '../../../../assets/images/goRoundLogo.svg';
import QBRoundLogo from '../../../../assets/images/QBLogo.svg';
import LinkProgressLogo from '../../../../assets/images/linkingprogress.svg';
import GCConnectingQBO from '../../../../assets/images/gcl-qbo.svg';
import GOQBOLogo from '../../../../assets/images/GoCardless-for-QuickBooks-horizontal-black.png';

import axios from '../../../../utils/axios';
import {
    AlignItems,
    Box,
    Button,
    ButtonVariant,
    Color,
    FontWeight,
    JustifyContent,
    P,
    Text,
    XYGrid,
    ButtonSize,
    ProgressBar,
    ProgressBarVariant,
    ColorScheme,
    TypeScale,
    Space,
    Select,
    Option,
    Toast,
    BannerVariant,
    BannerStatus,
    useToast,
    PlainLink
} from '@gocardless/flux-react';
import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {useNavigate} from 'react-router';

// ----------------------------------------------------------------------

export default function Hero() {
    const location = useLocation();

    const max = 3;
    const {toast, dismissToast} = useToast();

    const navigate = useNavigate();
    const value = 1;

    const [gclFeesState, setGclFreesState] = React.useState<any>({
        GoCardlessCrossCurrencyTransfers: [],
        GoCardlessFees: [],
        QuickbooksCurrencySettingsAccount: []
    });

    const [selectedValues, setSelectedValues] = React.useState<any>({
        GoCardlessAccountId:"",
        GoCardlessFeesAccountId:"",
        GoCardlessCrossCurrencyTransferAccountId:""
    });

    const onChangeSelect = (e) => {
        setSelectedValues({...selectedValues, [e.target.name]: e.target.value});
    };
    const checkIfKeyExist = (objectName, keyName) =>
        Object.keys(objectName).some((key) => key === keyName);


    const setValueToSelect = () => location?.state &&
        [
            'GoCardlessCrossCurrencyTransfers',
            'GoCardlessFees',
            'QuickbooksCurrencySettingsAccount'
        ].map((key) => checkIfKeyExist(location?.state, key)).some((d) => d !== false) &&
        setGclFreesState(location.state);

    const ToastGcl = (title, message, status) => toast((t) => (
        <Toast id={`toast_${t.id}`}
               title={title}
               variant={BannerVariant.Light}
               status={status}
               closeAction={{
                   onClose: () => dismissToast(t.id),
                   label: 'Close'
               }}>
            {message}
        </Toast>
    ));
    const validatePayload = (): string[] => {
        let Error: string[] = [];
        debugger;
        if ((gclFeesState?.QuickbooksCurrencySettingsAccount.length !== 0 && !('GoCardlessAccountId' in selectedValues) )
            || selectedValues.GoCardlessAccountId === '' && gclFeesState?.QuickbooksCurrencySettingsAccount.length !== 0)
            Error.unshift('Please select bank account');
        if ((gclFeesState?.GoCardlessFees.length !== 0 && !('GoCardlessFeesAccountId' in selectedValues) )
            || selectedValues.GoCardlessFeesAccountId === '' && gclFeesState?.GoCardlessFees.length !== 0 )
            Error.unshift('Please Select Fee account');
        /*if ((gclFeesState?.GoCardlessCrossCurrencyTransfers.length !== 0 && !('GoCardlessCrossCurrencyTransferAccountId' in selectedValues))
            || selectedValues.GoCardlessCrossCurrencyTransferAccountId === '' && gclFeesState?.GoCardlessCrossCurrencyTransfers.length !== 0)
            Error.unshift('Please select cross-currency transfers account');*/
        return Error;

    };
    const SaveQuickbooksReconciliation = async () => {
        const validateResult: string[] = validatePayload();
        if (validateResult.length !== 0) return validateResult.map((msg:string)=>ToastGcl('Error',msg, BannerStatus.Alert))
        try {
            const token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post(
                '/setting/saveQuickbooksReconciliation',
                {
                    quickbookReconciliation: selectedValues
                }
            );
            if (response) {
                ToastGcl('Success', "Setting updated", BannerStatus.Success);
                return navigate('/customer-onboarding/request-authorization');
            }
        } catch (e) {
            console.log('error', e);
        }
    };

    const setDefaultFeeAccount = async () => {
        if (gclFeesState?.GoCardlessFees?.some(d => d.Name?.toLowerCase() === "bank charges")) {
            const defaultFeeAccountEvent = {
              target: {
                name: "GoCardlessFeesAccountId",
                value: gclFeesState.GoCardlessFees.find(d => d.Name?.toLowerCase() === "bank charges").Id
              }
            };
            onChangeSelect(defaultFeeAccountEvent);
        }
    }

    useEffect(() => {
        setValueToSelect();
        setDefaultFeeAccount();
    }, [gclFeesState]);
    return (
        <XYGrid
            templateColumns="1fr auto"
            templateRows="repeat(2)"
            columnGap={1}
            rowGap={1}
            height={'100%'}
        >
            <Box
                gridColumn={1}
                layout="flex"
                justifyContent={JustifyContent.Center}
            >
                <Box>
                    <Box spaceAbove={2} spaceBefore={1}>
                        <img src={GOQBOLogo} alt={'GoCardless for QuickBooks logo'} width={'400px'} />
                    </Box>
                    <Box borderRadius={1} layout="flex">
                        <Box spaceBefore={1} spaceAfter={1}>
                            <Box
                                spaceBelow={4}
                                spaceAbove={3}
                                maxWidth={['100%', null, '200px']}
                            >
                                <ProgressBar
                                    borderRadius={0}
                                    variant={ProgressBarVariant.Solid}
                                    colorScheme={ColorScheme.OnLight}
                                    value={value}
                                    max={max}
                                    label={
                                        <Text id="radiusDemo2">
                                            <P 
                                                lineHeight={TypeScale.Size_03}
                                                size={TypeScale.Size_02}
                                                weight={400}
                                                color={Color.Greystone_1100}
                                                style={{
                                                    fontFamily: 'Haffer'
                                                }}
                                            >
                                                Step {value} of {max}
                                            </P>
                                        </Text>
                                    }
                                    aria-labelledby="radiusDemo2"
                                />
                            </Box>
                            <Box spaceBelow={2} maxWidth={['100%', null, '500px']} className={"m-26"}>
                                <P size={7} weight={600} className={'login-header-text'}>
                                    Now, where should we pay you?
                                </P>
                            </Box>
                            <Box 
                                maxWidth={['100%', null, '500px']}
                                spaceBelow={2}
                            >
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_02}
                                    weight={400}
                                    color={Color.Greystone_1100}
                                >
                                    Whenever you collect payments via GoCardless, your invoices are 
                                    automatically matched and assigned to the following accounts: 
                                </P>
                            </Box>
                            <Box
                                spaceBelow={1}
                                flexDirection={['column', null, 'row']}
                                maxWidth={['100%', null, '500px']}
                            >
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_03}
                                    weight={FontWeight.SemiBold}
                                    color={Color.Greystone_DarkMatter}
                                >
                                    Payments account
                                </P>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_02}
                                    color={Color.Greystone_1100}
                                >
                                    We will record invoice's transactions here.
                                </P>
                                <Select
                                    id="mainCourse"
                                    name="GoCardlessAccountId"
                                    onChange={onChangeSelect}
                                    className='mt-5'
                                    style={{
                                        fontFamily: 'Haffer',
                                        fontSize: '14px',
                                        color: '#6e685e'
                                    }}
                                >
                                    <Option value="">Select bank account</Option>
                                    {gclFeesState?.QuickbooksCurrencySettingsAccount?.map(
                                        (d, i) => (
                                            <Option value={d.Id} key={i}>
                                                {d.Name}
                                            </Option>
                                        )
                                    )}
                                </Select>
                            </Box>
                            <Space v={1} />
                            <Box 
                                spaceBelow={1}
                                flexDirection={['column', null, 'row']}
                                maxWidth={['100%', null, '500px']}
                            >
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_03}
                                    weight={FontWeight.SemiBold}
                                    color={Color.Greystone_DarkMatter}
                                >
                                    Fee account
                                </P>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_02}
                                    color={Color.Greystone_1100}
                                >
                                    We will record our fees here. GoCardless deducts transaction fees
                                    before paying you out. We keep a record of the fees in this account, so that&nbsp;
                                    <PlainLink href='https://quickbooks.intuit.com/learn-support/en-au/help-article/bank-feeds/account-bank-fees-matching-transactions-bank-feed/L67lX12yz_AU_en_AU' 
                                            target='_blank'
                                            style={{ textDecoration: 'underline' }}>
                                        QuickBooks can reconcile your invoices correctly.
                                    </PlainLink>
                                </P>
                                <Select
                                    id="mainCourse"
                                    name="GoCardlessFeesAccountId"
                                    onChange={onChangeSelect}
                                    value={selectedValues.GoCardlessFeesAccountId}
                                    className='mt-5'
                                    style={{
                                        fontFamily: 'Haffer',
                                        fontSize: '14px',
                                        color: '#6e685e'
                                    }}
                                >
                                    <Option value="">Select fee account</Option>
                                    {gclFeesState?.GoCardlessFees?.map((d, i) => (
                                        <Option value={d.Id} key={i} >
                                            {d.Name}
                                        </Option>
                                    ))}
                                </Select>
                            </Box>
                            <Space v={1} />
                            <Button
                                variant={ButtonVariant.PrimaryOnLight}
                                size={ButtonSize.Sm}
                                className={'m-8'}

                                onClick={SaveQuickbooksReconciliation}
                            >
                                Continue
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                gridColumn={2}
                width={[null,null,null,"700px"]}
                minHeight={'30vh'}
                bg={Color.Brownstone_100}
                style={{
                    borderRadius: '64px',
                    border: '32px solid #fff'
                }}
            >
                <Box
                    layout="flex"
                    height="100%"
                    justifyContent={JustifyContent.Center}
                    alignItems={AlignItems.Center}
                >
                    <Box>
                        <img
                            src={GCConnectingQBO} 
                            alt={'GoCardless and QuickBooks connection image'}
                            style={{
                                maxWidth: '100%'
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </XYGrid>
    );
}
