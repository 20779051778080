import { BannerStatus, BannerVariant, Toast, useToast } from '@gocardless/flux-react';
import React from 'react';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import { useApplicationContext } from 'src/contexts/ApplicationContext';
import SuspenseLoader from './SuspenseLoader';

interface ProtectedRouteProps {
    children: React.ReactElement;
    allowCondition?: (location: Location) => boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowCondition }) => {
    const { verificationStatus, isLoading } = useApplicationContext();
    const { toast, dismissToast } = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    console.log(verificationStatus)

    if (isLoading) {
        return (
            <>
                <SuspenseLoader/>
            </>
        );
    }

    if (allowCondition && allowCondition(location)) {
        return children;
    }

    if (!verificationStatus) {
        const ToastGCl = (title, message, status) => toast((t) => (
            <Toast id={`toast_${t.id}`}
                title={title}
                variant={BannerVariant.Light}
                status={status}
                closeAction={{
                    onClose: () => dismissToast(t.id),
                    label: 'Close'
                }}>
                {message}
            </Toast>
        ));

        ToastGCl("Verify your account", "You’ll need to verify your GoCardless account before you can start using this app", BannerStatus.Alert);
        navigate("/dashboard");
        return null;
    }

    return children;
};

export default ProtectedRoute;
