import {Box, Button, ButtonVariant, Dialog, Glyph, H3, Icon, JustifyContent, TypePreset} from '@gocardless/flux-react';
import SyncImage from 'src/assets/images/sync_image@2x.png';

export interface SyncStatusProps {
  isOpen: boolean;
  handleClose: () => void;
}

function SyncStatus(props: SyncStatusProps) {
  const { isOpen, handleClose } = props;
  return (
    <Dialog open={isOpen} aria-labelledby="syncStatusModal">
        <Box layout={'flex'} justifyContent={JustifyContent.End} >
            <Box onClick={handleClose}>
                <Icon name={Glyph.Close} />

            </Box>
        </Box>
      <Box layout="flex" justifyContent={JustifyContent.Center}>
        <img
          src={SyncImage}
          alt="direct debit logo"
          width={'160'}
          height={'150'}
        />
      </Box>
      <Box
        spaceBelow={1.5}
        layout="flex"
        justifyContent={JustifyContent.Center}
      >
        <H3 id="syncStatusModal" preset={TypePreset.Heading_06} className={'title-color'} >
          Sync requested
        </H3>
      </Box>
      <Box layout="flex" justifyContent={JustifyContent.Center}>
        <Button variant={ButtonVariant.PrimaryOnLight} onClick={handleClose}>
          Done
        </Button>
      </Box>
    </Dialog>
  );
}
export default SyncStatus;
