import {
  Menu,
  MenuRole,
  Button,
  ButtonVariant,
  P,
  Box,
  JustifyContent,
  Glyph,
  Separator,
  Color,
  Dropdown,
  MenuDensity,
  AlignItems,
  ButtonSize,
  Text,
  FontWeight,
  Space,
  Avatar,
  AvatarSize,
  ColorPreset,
  Icon
} from '@gocardless/flux-react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import headerlogo from '../../../assets/images/GoCardless-for-QuickBooks-logo.png';
import gocardlessRoundLogo from 'src/assets/images/goRoundLogo.svg';
import axios from 'src/utils/axios';
import SyncStatus from 'src/layouts/SidebarLayout/Modals/SyncStatus';
import { Launch } from '@mui/icons-material';
import { GOCARDLESS_URL, QUICKBOOKS_URL } from '../../../config';
import { useApplicationContext } from 'src/contexts/ApplicationContext';
import { Divider } from '@mui/material';

function index() {
  const location = useLocation();
  const navigate = useNavigate();

  const [syncStatusText, setSyncStatusText] = useState('Sync with QuickBooks');
  const [isSetSyncOpen, setSetSyncOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSyncEnabled, setSyncEnabled] = useState(true);
  const [syncTime, setsyncTime] = useState('');
  const { companyLogo } = useApplicationContext();
  const [orgid, setOrgId] = useState('');

  const syncStatus = async () => {
    try {
      setLoading(true);
      setSyncStatusText('In progress...');
      setSyncEnabled(false);
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const customerResponse = await axios.post('/customer/sendReSyncRequest');
      const deletedInvoicesResponse = await axios.get('/invoice/syncDeletedInvoices');
      const invoiceResponse = axios.get('/invoice/syncInvoices');
      if (customerResponse && invoiceResponse && deletedInvoicesResponse) {
        setSyncStatusText('Sync with QuickBooks');
        setSyncEnabled(true);
        getData();
      }
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setSyncStatusText('Sync with QuickBooks');
      setSyncEnabled(true);
    }
  };

  const handleSyncClose = () => {
    setSetSyncOpen(false);
    syncStatus();
  };

  const userDetails = JSON.parse(localStorage.getItem('user')); 
  const handleLogout = () => {
    return navigate('/', { state: { logout: true } });
  };

  const { pathname } = location;

  const splitLocation = pathname.split('/');

  console.log('Split', splitLocation);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('/customer/getCustomerConfiguration');
      if (response.status == 200) {
        if (response.data.Result.lastSyncDatetime != null) {
          const presentDate = new Date().getTime();
          const lastSyncDate = new Date(
            response.data.Result.lastSyncDatetime
          ).getTime();
          const diffDates = presentDate - lastSyncDate;

          setsyncTime(Math.floor(diffDates / 1000 / 60).toString());
          setOrgId(response.data.Result.GCOrgId);
        }
      }
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  }, []);

 

  useEffect(() => { 
    getData();
  }, [getData]);

  return (
    <>
      <Box>
        <Box className="header-logo" spaceBelow={1.5}>
          <img
            src={headerlogo}
            onClick={() => {
              navigate('/dashboard');
            }}
            width={'200px'}
            style={{ cursor: 'pointer' }}
          ></img>
        </Box>

        <SyncStatus isOpen={isSetSyncOpen} handleClose={handleSyncClose} />
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          gutterH={0.5}
          gutterV={1}
        >
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            size={ButtonSize.Sm}
            leftIcon={Glyph.Refresh}
            onClick={() => {
              setSetSyncOpen(true);
            }}
          >
            {syncStatusText}
          </Button>
        </Box>
        <Box layout="flex" alignItems={AlignItems.Start} gutterH={1}>
          <Text
            size={1}
            weight={FontWeight.Normal}
            color={ColorPreset.TextOnLight_03}
          >
            Last synced {syncTime} min ago
          </Text>
        </Box>
        <Space v={2} />
        <Box
          layout="flex"
          flexDirection={'column'}
          justifyContent={JustifyContent.SpaceBetween}
        >
          <Menu.Root role={MenuRole.List} className="final">
            <Menu.Link
              className={splitLocation[1] === 'dashboard' && 'active'}
              onClick={() => navigate('/dashboard')}
              accent={splitLocation[1] === 'dashboard' ? 'visible' : 'hidden'}
            >
              <P>Home</P>
            </Menu.Link>
            <Menu.Link
              className={splitLocation[1] === 'payouts' && 'active'}
              onClick={() => navigate('/payouts')}
              accent={splitLocation[1] === 'payouts' ? 'visible' : 'hidden'}
            >
              <P>Payouts</P>
            </Menu.Link>
            {/* <Menu.Link
            className={splitLocation[1] === 'activity' && 'active'}
            onClick={() => navigate('/activity')}
            accent={splitLocation[1] === 'activity' ? 'visible' : 'hidden'}
          >
            <P>Activity</P>
          </Menu.Link> */}
            <Separator
              direction="block-horizontal"
              align="center"
              length={'80%'}
              spacing={[[1, 0]]}
            />
            <Menu.Link
              className={splitLocation[1] === 'customers' && 'active'}
              onClick={() => navigate('/customers')}
              accent={splitLocation[1] === 'customers' ? 'visible' : 'hidden'}
            >
              <P> Customers</P>
            </Menu.Link>
            <Menu.Link
              className={splitLocation[1] === 'invoices' && 'active'}
              onClick={() => navigate('/invoices')}
              accent={splitLocation[1] === 'invoices' ? 'visible' : 'hidden'}
            >
              <P>Invoices</P>
            </Menu.Link>
            <Menu.Link
            className={splitLocation[1] === 'refunds' && 'active'}
            onClick={() => navigate('/refunds')}
            accent={splitLocation[1] === 'refunds' ? 'visible' : 'hidden'}
          >
            <P>Refunds</P>
          </Menu.Link>
            <Separator
              direction="block-horizontal"
              align="center"
              length={'80%'}
              spacing={[[1, 0]]}
            />
            <Menu.Link 
              onClick={() => window.open(GOCARDLESS_URL)}
              rightIcon={Glyph.Share}
              style={{ 
                color: '#5b4e3c'
              }}
            >
              <P 
                color={Color.Brownstone_1200}
              >
                Go to GoCardless Dashboard{' '}
              </P>
            </Menu.Link>
            <Menu.Link 
              onClick={() => window.open(QUICKBOOKS_URL)}
              rightIcon={Glyph.Share}
              style={{ 
                color: '#5b4e3c'
              }}
            >
              <P 
                color={Color.Brownstone_1200}
              >
                Go to QuickBooks Dashboard{' '}
              </P>
            </Menu.Link>
          </Menu.Root>
        </Box>
      </Box>


      <Box
        style={{ position: 'fixed', bottom: 16, width: '100%' }}
        height={'10vh'}
      >
        <Dropdown
          controls="user-options"
          trigger={(props) => {
            return (
              <Box layout="flex" alignItems={AlignItems.Center}>
                <Avatar
                  src={companyLogo || gocardlessRoundLogo}
                  alt={`${userDetails?.name} profile photo`}
                  size={AvatarSize.Sm}
                  style={{ marginLeft: '10px' , position: 'relative'}}
                />
                <Button
                  {...props}
                  rightIcon={Glyph.ChevronRight}
                  variant={ButtonVariant.TextOnLight}
                  style={{
                    border: 'none',
                    background: '#FAF9F7',
                    textDecoration: 'none',
                    marginLeft: '-40px',
                    width: '200px',
                    wordBreak: 'break-word',
                    paddingRight: '10px',
                  }}
                >
                  {userDetails?.name}
                </Button>
              </Box>
            );
          }}
          maxWidth={'192px'}
        >
          <Box width={'240px'}>
            <Menu.Root
              id="user-options"
              role={MenuRole.Menu}
              density={MenuDensity.Compact}
            >

              <Menu.Link style={{ textDecoration: 'none', display: 'flex', flexDirection: 'column',cursor: 'default' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                  <span style={{ marginRight: '8px', marginTop: '2px' }}>
                    <Icon
                      color={Color.Greystone_1400}
                      size="16px"
                      name={Glyph.Company}
                    />
                  </span>
                  <P style={{ fontSize: '16px', fontWeight: 'bold' }}>Company ID</P>
                </div>

                <span style={{ color: 'grey' }}>{orgid}</span>

                <div style={{ height: '16px' }}></div>
                <Separator
                  direction="block-horizontal"
                  length={'75%'}
                />

                <div style={{ height: '16px' }}></div>
              </Menu.Link>
              <Menu.Link
                onClick={() => window.open(`${GOCARDLESS_URL}/settings`)}
              >
                <P>
                  Edit Account{' '}
                  <Launch
                    fontSize="inherit"
                    color="inherit"
                    style={{ marginLeft: '49px' }}
                  />
                </P>
              </Menu.Link>
              <Menu.Link onClick={handleLogout}>
                <P style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
                  Log out
                  <span style={{ marginLeft: '87px' }}> 
                    <Icon
                      name={Glyph.CloseCircle}
                    />
                  </span>
                </P>
              </Menu.Link>
            </Menu.Root>
          </Box>
        </Dropdown>
      </Box>
    </>
  );
}

export default index;
