import { useNavigate } from 'react-router-dom';
import GOQBOLogo from '../../../../assets/images/GoCardless-for-QuickBooks-horizontal-black.png';
import collect1 from '../../../../assets/images/collect1.png';
import manually1 from '../../../../assets/images/manually1.png';
import PaymentImage from '../../../../assets/images/request_authorization_steps.png';
import axios from '../../../../utils/axios';
import {
    AlignItems,
    BannerStatus,
    BannerVariant,
    Box,
    Button,
    ButtonSize,
    ButtonVariant,
    Checkbox,
    Color,
    ColorScheme,
    FieldSet,
    FontWeight,
    Glyph,
    Icon,
    JustifyContent,
    P,
    PlainLink,
    ProgressBar,
    ProgressBarVariant,
    Radio,
    Space,
    Text,
    TextAlign,
    Toast,
    ToggleControlPosition,
    ToggleVariant,
    TypeScale,
    useToast,
    XYGrid
} from '@gocardless/flux-react';
import React from 'react';

// ----------------------------------------------------------------------

export default function Hero() {
    const navigate = useNavigate();
    const [automaticRequest, setAutomaticRequest] = React.useState('true');

    const max = 3;
    const value = 3;
    const { toast, dismissToast } = useToast();

    const ToastGcl = (title, message, status) =>
        toast((t) => (
            <Toast
                id={`toast_${t.id}`}
                title={title}
                variant={BannerVariant.Light}
                status={status}
                closeAction={{
                    onClose: () => dismissToast(t.id),
                    label: 'Close'
                }}
            >
                {message}
            </Toast>
        ));
    const TakePaymentAutomatically = async (): Promise<void> => {
        try {
            const token = localStorage.getItem('token');
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await axios.post('/setting/saveQuickbooksAutomation', {
                quickbookAutomation: {
                    IsTakePaymentAutomaticallyOnTheInvoiceDueDate: automaticRequest === 'true',
                    IsAutoRecordReceivePaymentInQBO: true
                }
            });
            if (response) {

                const token = localStorage.getItem('token');
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                await axios.post('/auth/oneTimeSync',);
                ToastGcl('Success', 'Setting updated', BannerStatus.Success);
                return navigate('/dashboard');
            }
        } catch (err: any) {
            console.log(err.message);
        }
    };
    return (
        <XYGrid
            templateColumns="1fr auto"
            templateRows="repeat(2)"
            columnGap={1}
            rowGap={1}
            height={'100%'}
        >
            <Box
                gridColumn={1}
                layout="flex"
                justifyContent={JustifyContent.Center}
            >
                <Box>
                    <Box spaceAbove={2} spaceBefore={1}>
                        <img src={GOQBOLogo} alt={'GoCardless for QuickBooks logo'} width={'400px'} />
                    </Box>
                    <Box borderRadius={1} layout="flex">
                        <Box spaceBefore={1} spaceAfter={1}>
                            <Box
                                spaceBelow={4}
                                spaceAbove={3}
                                maxWidth={['100%', null, '200px']}
                            >
                                <ProgressBar
                                    borderRadius={0}
                                    variant={ProgressBarVariant.Solid}
                                    colorScheme={ColorScheme.OnLight}
                                    value={value}
                                    max={max}
                                    label={
                                        <Text id="radiusDemo2">
                                            <P 
                                                lineHeight={TypeScale.Size_03}
                                                size={TypeScale.Size_02}
                                                weight={400}
                                                color={Color.Greystone_1100}
                                                style={{
                                                    fontFamily: 'Haffer'
                                                }}
                                            >
                                                Step {value} of {max}
                                            </P>
                                        </Text>
                                    }
                                    aria-labelledby="radiusDemo2"
                                />
                            </Box>
                            <Box spaceBelow={2} maxWidth={['100%', null, '500px']} className={"m-26"}>
                                <P size={7} weight={600} className={'login-header-text'}>
                                    Automate payment collection
                                </P>
                            </Box>
                            <Box maxWidth={['100%', null, '500px']}>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_02}
                                    weight={400}
                                    color={Color.Greystone_1100}
                                >
                                    After a customer sets up a mandate, you are in control of payment
                                    collection. Simply create an invoice on QuickBooks, and the payment
                                    will be scheduled. Your customer always receives a confirmation email. 
                                </P>
                            </Box>
                            <Box
                                spaceBelow={1}
                                flexDirection={['column', null, 'row']}
                                maxWidth={['100%', null, '500px']}
                            >
                                <FieldSet className={'radio-fieldset-on-boarding'}>
                                    <Checkbox
                                        controlPosition={ToggleControlPosition.Start}
                                        variant={ToggleVariant.Emphasized}
                                        name="controlPosition"
                                        checked={automaticRequest === "true"}
                                        value="true"
                                        onClick={(e: any) => setAutomaticRequest(e.target?.value)}
                                    >
                                        <XYGrid
                                            templateColumns="1fr auto"
                                            templateRows="repeat(2)"
                                            columnGap={1}
                                            rowGap={1}
                                            height={'100%'}
                                        >
                                            <Box gridColumn={1}>
                                                <Text
                                                    size={1}
                                                    weight={FontWeight.Bold}
                                                    color={Color.Ultraviolet_600}
                                                >
                                                    Recommended
                                                </Text>
                                                <P 
                                                    size={3}
                                                    weight={FontWeight.Bold}
                                                    textAlign={TextAlign.Justify}
                                                > 
                                                    Automatically get paid when invoicing
                                                </P>
                                                <Text 
                                                    size={2}
                                                    weight={FontWeight.Light}
                                                    textAlign={TextAlign.Left}
                                                    color={Color.Greystone_1100}
                                                >
                                                    Set and forget. Set up invoices on QuickBooks and we will collect the payment on due date.
                                                </Text>
                                            </Box>
                                        </XYGrid>
                                    </Checkbox>
                                    <Checkbox
                                        controlPosition={ToggleControlPosition.Start}
                                        variant={ToggleVariant.Emphasized}
                                        name="controlPosition"
                                        checked={automaticRequest === "false"}
                                        value="false"
                                        onClick={(e: any) => setAutomaticRequest(e.target?.value)}
                                    >
                                        <XYGrid
                                            templateColumns="1fr auto"
                                            templateRows="repeat(2)"
                                            columnGap={1}
                                            rowGap={1}
                                            height={'100%'}
                                        >
                                            <Box gridColumn={1}>
                                                <P 
                                                    size={3}
                                                    weight={FontWeight.Bold}
                                                > 
                                                    Manually collect payments
                                                </P>
                                                <P 
                                                    size={2}
                                                    weight={FontWeight.Light}
                                                    color={Color.Greystone_1100}
                                                >
                                                    Login to this dashboard and select which invoices you'd like to request payments from.
                                                </P>
                                            </Box>
                                        </XYGrid>
                                    </Checkbox>
                                </FieldSet>
                            </Box>
                            <Space v={1} />

                            <Box maxWidth={['100%', null, '500px']}>
                                <P
                                    lineHeight={TypeScale.Size_03}
                                    size={TypeScale.Size_02}
                                    weight={400}
                                    color={Color.Greystone_1100}
                                >
                                    You can change these settings at any time.
                                </P>
                            </Box>
                            <Space v={1} />

                            <Button
                                variant={ButtonVariant.PrimaryOnLight}
                                size={ButtonSize.Sm}
                                onClick={TakePaymentAutomatically}
                            >
                                Go to app
                            </Button>

                            <Space v={3} />
                            <Box layout="flex" >
                                <P>
                                    <Icon size="15px" className={'title-color'} name={Glyph.ArrowBack} />
                                </P>{' '}&nbsp;&nbsp;
                                <PlainLink
                                    href="javascript:void(0);"
                                    decoration={'underline'}
                                    weight={FontWeight.Bold}
                                    onClick={() => navigate(-1)}
                                    className={'title-color'}
                                    size={2}
                                >
                                    Back to previous step
                                </PlainLink>
                            </Box>
                            <Space v={1} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                gridColumn={2}
                width={[null,null,null,"700px"]}
                minHeight={'30vh'}
                bg={Color.Dawn_200}
                style={{
                    borderRadius: '64px',
                    border: '32px solid #fff'
                }}
            >
                <Box
                    layout="flex"
                    height="100%"
                    justifyContent={JustifyContent.Center}
                    alignItems={AlignItems.Center}
                >
                    <Box>
                        <img
                            src={PaymentImage}
                            alt="PaymentImage"
                            style={{
                                maxWidth: '100%', 
                                transform: 'scale(0.65)'
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </XYGrid>
    );
}
