import React, { createContext, useContext, useState, FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'src/utils/axios';

type ApplicationContextType = {
  verificationStatus: boolean;
  homeCurrency: string;
  companyLogo: string;
  isLoading: boolean;
};

const ApplicationContext = createContext<ApplicationContextType | undefined>(undefined);

const isProtectedRoute = (pathname) => {
  const protectedPaths = [
    '/dashboard',
    '/customers',
    '/invoices',
    '/payouts',
    '/settings',
    '/refunds',
    // Add other protected paths as needed
  ];
  return protectedPaths.some(protectedPath => pathname.startsWith(protectedPath));
};

type ApplicationProviderProps = {
  children: ReactNode;
};

export const ApplicationProvider: FC<ApplicationProviderProps> = ({ children }) => {
  const [verificationStatus, setVerificationStatus] = useState<boolean>(false);
  const [homeCurrency, setHomeCurrency] = useState<string>("USD");
  const [companyLogo, setCompanyLogo] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/user/dashboard`);
      setVerificationStatus(response?.data?.Result?.verificationStatus === "successful");
      setHomeCurrency(response?.data?.Result?.homeCurrency);
      setCompanyLogo(response?.data?.Result?.companyLogo)
    } catch (error) {
      console.error('Error fetching verification status:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isProtectedRoute(location.pathname)) {
      fetchData();
    }
  }, [location.pathname]);

  return (
    <ApplicationContext.Provider value={{ verificationStatus, homeCurrency, companyLogo, isLoading }}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error('useApplicationContext must be used within an ApplicationProvider');
  }
  return context;
};
