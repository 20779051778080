import React, { FC, ReactNode } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import {
  Aside,
  XYGrid,
  Sidebar,
  Box,
  MainArea,
  JustifyContent,
  Footer,
  P,
  TypePreset,
  ColorPreset,
  FontWeight,
  AlignItems,
  TypeScale,
  Color,
  Separator,
  Space,
  Button,
  Glyph,
  ButtonVariant,
  AlignContent
} from '@gocardless/flux-react';
import SidebarMenu from './SidebarMenu';
import HeaderLayout from './Header';
import '../../App.css';
import { GOCARDLESS_URL } from 'src/config';
import { Launch } from '@mui/icons-material';
interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  return (
    <>
      <XYGrid
        height="300px"
        templateAreas={`
        'topnav topnav'
        'sidebar main'
        'footer footer'
      `}
        templateColumns="max-content auto"
        templateRows="0px 1fr auto"
      >
        <Aside className={'gcl-sidebar'} gridArea="sidebar" gridColumn={2}>
          <Sidebar
            open={open}
            closeAction={{ label: 'Close', onClose: () => setOpen(false) }}
          >
            <Box 
              className="gcl-sidebar-box"
            >
              <SidebarMenu />
            </Box>
          </Sidebar>
        </Aside>
        <MainArea
          layout="flex"
          justifyContent={JustifyContent.SpaceBetween}
          flexDirection={'column'}
          gutterH={[1, null, 1]}
          gutterV={1}
          spaceBefore={1}
          spaceAfter={1}
          gridArea="main"
          gridColumn={3}
          // minHeight={'70vh'}
          minWidth={['370px', '768px', '768px', '1120px']}
          maxWidth={'1200px'}
        >
          <HeaderLayout handleOpen={setOpen} />
          <Box>
            <Outlet />
          </Box>
          { location.pathname == '/dashboard' ? (
            <Footer
              gridArea="footer"
              layout="flex"
              flexDirection="column"
              justifyContent={JustifyContent.Center}
              alignItems={AlignItems.Center}
              className="my-footer"
            >
              <Space v={1}/>
              <Button
                variant={ButtonVariant.TextOnLight}
                style={{ 
                  border: 'none', 
                  background: 'none', 
                  textDecoration: 'none',
                  color: '#6e685e',
                  zIndex: 999 
                }}
                onClick={() => window.open('https://support.gocardless.com/hc/en-gb/requests/new?ticket_form_id=134125')}
                rightIcon={Glyph.Share}
              >
                <P
                  preset={TypePreset.Body_04}
                  color={ColorPreset.TextOnLight_03}                  
                  size={TypeScale.Size_01}
                  weight={FontWeight.Medium}
                  decoration={'underline'}
                  className='raiseTicket'           
                >
                  Raise a support ticket{' '}
                </P>
              </Button>
              {/* <Space v={1}/> */}
            </Footer>
          ) : (
            <Footer
              gridArea="footer"
              layout="flex"
              flexDirection="column"
              justifyContent={JustifyContent.Center}
              alignItems={AlignItems.Center}
              className="my-footer"
            >
              <Space v={1}/>
            </Footer>
          )
        }
        </MainArea>
      </XYGrid>
    </>
  );
};

export default SidebarLayout;
