import headerlogo from '../../../assets/images/QuickBooks-white-logo.png';
import {
    Header,
    Visibility,
    Box,
    IconButton,
    AlignItems,
    ButtonVariant,
    Glyph,
    Space,
    Dropdown,
    Button,
    Menu,
    MenuRole,
    MenuDensity,
    JustifyContent
} from '@gocardless/flux-react';
import { Navigate } from 'react-router';
import { useNavigate } from "react-router-dom";


export default function HeaderLayout(handleOpen) {
    const userDetails = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate()
    if (!userDetails) {
        return <Navigate to={'/login'} />;
    }

    return (
        <Header layout={'flex'} style={{ zIndex: '10'}}>
            <Box>
                    <Dropdown
                        controls="settings-top"
                        trigger={(props) => {
                            return (
                                <Button
                                    {...props}
                                    leftIcon={Glyph.Settings}
                                    variant={ButtonVariant.TextOnLight}
                                    style={{border: 'none', background: 'none', textDecoration: 'none', position: 'relative' }}
                                >
                                    Settings
                                </Button>
                            );
                        }}
                        maxWidth={'192px'}
                    >
                        <Box width={'192px'}>
                            <Menu.Root
                                id="settings-top"
                                role={MenuRole.Menu}
                                density={MenuDensity.Compact}
                            >
                                {/* TODO: Remove Account and Log out options, move to the profile */}
                                {/* <Menu.Button
                                    onClick={() => navigate('/account')}>Account</Menu.Button>
                                <Menu.Button onClick={handleLogout}>Log out</Menu.Button> */}

                                <Menu.Button onClick={() => navigate('/settings/app-settings', { state: { tabName: 'app-settings' } })}>App settings</Menu.Button>
                                <Menu.Button onClick={() => navigate('/settings/connected-apps', { state: { tabName: 'connected-apps' } })}>Connected accounts</Menu.Button>
                                <Menu.Button onClick={() => navigate('/settings/automation', { state: { tabName: 'automation' } })}>Automation</Menu.Button>
                                <Menu.Button onClick={() => navigate('/settings/bank-accounts', { state: { tabName: 'bank-accounts' } })}>Bank accounts</Menu.Button>
                            </Menu.Root>
                        </Box>
                    </Dropdown>
                </Box>
        </Header>
    );
}
