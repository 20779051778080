import {
    Box,
    Color,
    FontWeight,
    JustifyContent,
    P,
    Space,
    TypeScale,
    PlainLink
} from '@gocardless/flux-react';
import { GOCARDLESS_URL } from 'src/config';

function Hero() {

    const user = JSON.parse(localStorage.getItem("user"))
    return (
        <>
            <Space v={1}/>
            <Box
                layout="flex"
                flexDirection={['column', null, 'row']}
                width={'100%'}
                justifyContent={JustifyContent.SpaceBetween}
            >
                <P
                    lineHeight={TypeScale.Size_07}
                    size={TypeScale.Size_06}
                    weight={FontWeight.SemiBold}
                    color={Color.Greystone_DarkMatter}
                >
                    User settings for {user?.name}
                </P>
            </Box>
            <Space v={2}/>

            <Box
                bg={Color.White}
                width={'100%'}
                borderRadius={0.5}
                gutterH={2}
                gutterV={2}
            >
                <P lineHeight={4} size={4} weight={600}>User
                    management</P>
                <Space v={1}/>
                <P>
                    Your user settings are managed via the GoCardless dashboard{' '}
                    <PlainLink
                        href={`${GOCARDLESS_URL}/settings`}
                        textDecoration={'underline'}
                        weight={FontWeight.SemiBold}
                    >
                        click here to see
                        your user settings.
                    </PlainLink>
                    .
                </P>
            </Box>

        </>
    );
}

export default Hero;
